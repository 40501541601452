import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const Logo = ({ isWhite, className }) => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      logo: file(relativePath: { eq: "logos/itpro-new-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      logoWhite: file(relativePath: { eq: "logos/itproactive-logo-white.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  const imageLogo = data.logo.childImageSharp.fluid
  const imageWhite = data.logoWhite.childImageSharp.fluid

  return (
    <Img fluid={isWhite ? imageWhite : imageLogo} alt='IT Proactive logo' className={className} />
  )
}

Logo.propTypes = {
  isWhite: PropTypes.bool,
  className: PropTypes.string,
}

export default Logo